import Modal from "../components/Modal";

import {Link} from "react-router-dom";

const NotFound = () => (
  <>
    <div className="container">
      <div className="row my-5">
        <div className="col-12 col-md-10 col-lg-8">
          <h1>Sidan kan inte hittas</h1>
          <Link to="/">Gå till startsidan</Link>
        </div>
      </div>
    </div>
  </>
);

export default NotFound;
