import Modal from "../components/Modal";
import ExternalLinkIcon from "../components/ExternalLinkIcon";
import {Fragment, useEffect, useState} from 'react'

const Blankettarkiv = ({isOpen, setIsOpen}) => {

  const toggleModal = () => {
    setIsOpen((state) => !state);
  };

  const renderForms = (sections) => {
    return Object.entries(sections).sort((a,b) => {
      if (a[0] === 'generell')
        return -1;

      if (b[0] === 'generell')
        return 1;

      if (a[0] < b[0])
        return -1;
      if (a[0] > b[0])
        return 1;
      return 0;
    }).map(([key, section]) => {
      return (
        <Fragment key={key}>
          <h3 className="mb-3">{section.title === 'Generell' ? 'Gemensamma blanketter för alla skolor' : section.title}</h3>
          <div className="list-group mb-5">
            {section.forms.sort((a, b) => {
              if(a.title < b.title) { return -1; }
              if(a.title > b.title) { return 1; }
              return 0;
            }).map(item => <a key={item.id} href={item.url} target="_blank" rel="noreferrer" className="list-group-item list-group-item-action">{item.title}<ExternalLinkIcon fill={"#333"} /></a>
            )}
          </div>
        </Fragment>
      )
    })
  }

  const [state, setState] = useState({loading: true, sections: {}});

  useEffect(() => {
    fetch('https://www.raoulwallenbergskolan.se/wp-json/wp/v2/forms?per_page=100')
      .then(data => data.json())
      .then(data => {
        const sections = {};

        data.forEach(item => {
          //Find all blank spaces
          const regex = / /g;
          //replace them with hyphen
          const key = item.meta["school-select"].toLowerCase().replace(regex, '-');

          if (key in sections) {
            sections[key].forms.push({
              id: item.id,
              title: item.title.rendered,
              url: item.meta["form_media_url"]
            })
          } else {
            sections[key] = {
              title: item.meta["school-select"],
              forms: [{
                id: item.id,
                title: item.title.rendered,
                url: item.meta["form_media_url"]
              }]
            }
          }
        });

        setState(prevState => ({
          loading: !prevState.loading,
          sections
        }));
      })
  }, []);

  return (
    <>
      <div className="container">
        <div className="row my-5">
          <div className="col-12 col-md-10 col-lg-8">
            <h1>RWS Blankettarkiv</h1>
            <div className="h2 text-muted mb-4">Blanketter för nedladdning och utskrift.</div>
            <p>Ni som inte kan genomföra en digital signering kan ladda ner blanketterna i pappersformat. Ifylld blankett skickas direkt till resp. skola.</p>
            <p>RWS Bagartorp, Bagartorpsringen 20, 170 64 Solna</p>
            <p>RWS Bromma, Beckombergavägen 301, 168 63 Bromma</p>
            <p>RWS Järvastaden, Fridensborgsvägen 100, 170 68 Solna</p>
            <p>RWS Lidköping, Fabriksgatan 4 port 7, 531 30 Lidköping</p>
            <p>RWS Skövde, Hammargatan 1, 541 45 Skövde</p>
            <p>RWS Uppsala, Kungsgatan 18, 753 32 Uppsala</p>
            <hr className={"my-5"}/>
            {!state.loading ? renderForms(state.sections) :
              <div className="spinner-border text-primary" role="status"/>
            }
          </div>
        </div>
      </div>
      {isOpen && <Modal toggleModal={toggleModal} />}
    </>
  )};

export default Blankettarkiv;
