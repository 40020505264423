import Modal from "../components/Modal";

const InskrivningPage = ({isOpen, setIsOpen}) => {

  const toggleModal = () => {
    setIsOpen((state) => !state);
  };

  return (
  <>
  <div className="container">
    <div className="row my-5">
      <div className="col-12 col-md-10 col-lg-8">
        <h1>RWS intagningsportal</h1>
        <div className="h2 text-muted mb-4">Välkommen till intagningsportalen</div>
        <p>Ni har via vårt digitala ansökningssystem erbjudits och tackat ja till plats för ert barn i en av våra skolor. Nästa steg är att fullfölja intagning till skolan genom att ge oss en del information om ert barn och samtidigt ge oss en del godkännanden. Godkännande sker genom digital signering.</p>
        <p>Båda vårdnadshavarna (om inte ensam vårdnad gäller) måste ge sitt godkännande genom digital signering.</p>
        <p><strong>Blanketter ska fyllas i och signeras:</strong></p>
        <ul className="list-group mb-4">
          <li className="list-group-item list-group-item-primary"><strong>Intagningsblanketten</strong><br/>Information om ert barn som vi behöver inför skolstart.</li>
          {/*<li className="list-group-item list-group-item-primary"><strong>Journalrekvisitionen</strong><br/>Ger RWS tillstånd att hämta in ert barns journal från föregående skola eller Barnavårdscentral (BVC)</li>*/}
        </ul>
        <p>Blanketterna är digitala och ska signeras med BankID av båda vårdnadshavarna (om inte ensam vårdnad gäller). Om ni inte har tillgång till BankID vill vi att ni omedelbart hör av er till respektive skoladministratör (se nedan) så skickar vi ut de olika inskrivningsdokumenten per post.</p>
        <h5>Guide för ifyllande av blanketterna</h5>
        <button className="btn btn-primary" onClick={() => {toggleModal()}}>Öppna guide</button>
        <hr className="my-4"/>
        <h2 className="mb-3">Till digital signering</h2>
        <div className="card mb-4">
          <div className="card-body">
            <h4>Intagningsblanketten</h4>
            <p>Klicka på knappen för att komma till digital ifyllning samt signering av blanketten.</p>
            <a href="https://go-printer.scrive.com/link/6f8b6a5c-a02e-490b-b5bc-2f2e6fb54e91" className="btn btn-primary">Fyll i Intagningsblanketten <svg width="18px" style={{marginLeft: 5, marginTop: -5}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="#ffffff" d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"/></svg></a>
          </div>
        </div>
        {/*<div className="card mb-4">
              <div className="card-body">
                <h4>Journalrekvisitionen</h4>
                <p>Klicka på knappen för att komma till digital ifyllning samt signering av blanketten.</p>
                <a href="https://go-printer.scrive.com/link/5b390792-20bd-4c1b-93b4-ed75461c5b82" className="btn btn-primary">Fyll i Journalrekvisitionen <svg width="18px" style={{marginLeft: 5, marginTop: -5}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="#ffffff" d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"/></svg></a>
              </div>
              </div>*/}
        <h5>Överlämning från förutvarande förskola eller grundskola</h5>
        <p>Vi är angelägna om att arbetet med ert barn ska komma igång så snabbt och professionellt som möjligt efter skolstart, och därför kommer vi ta initiativ till överlämning från den förutvarande förskolan eller grundskolan. Vi har stöd för detta enligt gällande regelverk och gör det av omsorg om vår nya elev. Vårdnadshavare som dock känner osäkerhet inför detta ombeds att meddela skolledningen i ett email till skoladministratören nedan.</p>
        <hr className="my-5"/>
        <h5 className="my-4">Har du ytterligare frågor?</h5>
        <p><strong>RWS Bagartorp</strong>, Kristina Peponi, <a href="mailto:kristina.peponi@rws.se">kristina.peponi@rws.se</a>, <a href="tel:0761094623">076-109 46 23</a></p>
        <p><strong>RWS Järvastaden</strong>, Katarina Svensson, <a href="mailto:katarina.svensson@rws.se">katarina.svensson@rws.se</a>, <a href="tel:0730243706">073-024 37 06</a></p>
        <p><strong>RWS Bromma</strong>, Mats Weinoff, <a href="mailto:mats.weinoff@rws.se">mats.weinoff@rws.se</a>, <a href="tel:0737081790">073-708 17 90</a></p>
        <p><strong>RWS Skövde</strong>, Therese Johansson, <a href="mailto:therese.johansson@rws.se">therese.johansson@rws.se</a>, <a href="tel:0722306481">072-230 64 81</a></p>
        <p><strong>RWS Uppsala</strong>, Erika Erfäldt, <a href="mailto:erika.erfaldt@rws.se">erika.erfaldt@rws.se</a>, <a href="tel:0761262246">076-126 22 46</a></p>
        <p><strong>RWS Lidköping</strong>, Zandra Joelsson, <a href="mailto:zandra.joelsson@rws.se">zandra.joelsson@rws.se</a>, <a href="tel:0761335557 ">076-133 55 57</a></p>
      </div>
    </div>
  </div>
  {isOpen && <Modal toggleModal={toggleModal} />}
  </>
)};

export default InskrivningPage;
