import {Fragment} from 'react';

const Modal = ({toggleModal}) => {
  return (
    <Fragment>
    <div className="modal fade show" style={{display: 'block'}} tabIndex="-1">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header"><div className="h4 mb-0">Guide för ifyllande av blanketterna</div>
            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" onClick={() => toggleModal()}>&times;</button></div>
          <div className="modal-body">
            <h5>Vårdnadshavare 1</h5>
            <ul className="list-group mb-3">
              <li className="list-group-item"><strong>1.</strong> Klicka på knappen ”Fyll i intagningsblanketten” nedan. Du kommer då till den digitala intagningsblanketten.</li>
              <li className="list-group-item"><strong>2.</strong> Fyll i de olika uppgifterna i blanketten.</li>
              <li className="list-group-item"><strong>3.</strong> Klicka på knappen ”Skicka dokument” i slutet av blanketten. Du får då upp blanketten i ifyllt skick. Granska så att uppgifterna är korrekta och komplettera med markering i checkboxar. Om någon uppgift är felaktig gå tillbaka till intagningsportalen och klicka på knappen "Fyll i intagningsblanketten" igen.</li>
              <li className="list-group-item"><strong>4.</strong> Signera den ifyllda blanketten med hjälp av ditt BankID. Den signerade blanketten skickas via email med automatik till vårdnadshavare 2 för digital signering. Därefter hamnar du med automatik på intagningsportalen.</li>
              {/*<li className="list-group-item"><strong>5.</strong> På intagningsportalen, klicka på knappen ”Fyll i Journalrekvisitionen”.</li>*/}
              {/*<li className="list-group-item"><strong>6.</strong> Fyll i uppgifterna i Journalrekvisitionen och klicka på knappen ”Skicka dokument” längst ner. Du får då upp blanketten i ifyllt skick och signerar blanketten längst ner om du är nöjd med innehållet. Om något inte är korrekt gå tillbaka till intagningsportalen och klicka på ”Fyll i Journalrekvisitionen”. Vid signering med BankID skickas blanketten via email till vårdnadshavare 2 för digital signering.</li>*/}
            </ul>
            <h5>Vårdnadshavare 2</h5>
            <ul className="list-group mb-3">
              <li className="list-group-item"><strong>1.</strong> Klicka på länkar till de båda blanketterna som kommit med email. Titta igenom innehållet.</li>
              <li className="list-group-item"><strong>2.</strong> Signera blanketterna med BankID om du är nöjd med innehållet. De två signerade blanketterna skickas då med automatik till oss för vidare handläggning. Om du inte är nöjd med innehållet klicka på knappen ”Avvisa”. Blanketten skickas då tillbaka till vårdnadshavare 1, varpå processen får göras om.</li>
            </ul>
            <button className="btn btn-primary float-right" onClick={() => toggleModal()}>Stäng</button>
          </div>
        </div>
      </div>
    </div>
    <div className="modal-backdrop fade show" onClick={() => toggleModal()}></div>
    </Fragment>)
};


export default Modal;
