import * as React from 'react';
import Logo from './components/Logo';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink
} from "react-router-dom";
import { default as Home} from "./pages/Inskrivning";
import { default as Blankettarkiv} from "./pages/Blankettarkiv";
import NotFound from "./pages/NotFound";
import {useState} from "react";

function App() {

  const [isOpen, setIsOpen] = useState(false);

  const [isNavOpen, setIsNavOpen] = useState(false);

  return (
    <Router>
      <div className={`App ${isOpen ? 'modal-open' : ''}`}>
        <header className="header py-3 bg-primary">
          <div className="container">
            <nav className={"navbar navbar-dark navbar-expand-lg"}>
              <Logo />
              <button className="navbar-toggler" type="button"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
              onClick={() => {setIsNavOpen((state) => !state)}}>
                <span className="navbar-toggler-icon" style={{width: '2.5em', height: '2.5em'}} />
              </button>
              <div className={`${isNavOpen ? '' : 'collapse'} navbar-collapse`}>
                <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
                  <li className="nav-item"><NavLink exact to="/" className="nav-link">Intagningsportal</NavLink></li>
                  <li className="nav-item"><NavLink exact to="/blankettarkiv" className="nav-link">Blankettarkiv</NavLink></li>
                </ul>
              </div>
            </nav>
          </div>
        </header>
        <Switch>
          <Route exact path="/">
            <Home isOpen={isOpen} setIsOpen={setIsOpen} />
          </Route>
          <Route exact path="/blankettarkiv" component={Blankettarkiv} />
          <Route component={NotFound} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
